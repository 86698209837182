import AuthorizationLayout from '../../lib/layout/AuthorizationLayout'
import UserSignInMethod from '../../lib/webshop/UserSignInMethod'

import { useTranslation } from 'react-i18next'
import FooterText from './FooterText'

/**
 * User sign up form
 * @returns JSX.Element
 */

export function UserSignIn({
  onLogin,
  onRegister,
  hideHeader,
  hideDoctor,
  hideRegister = false,
  className,
  auth0app = 'webshop'
}: {
  onLogin?(): void
  onRegister?(): void
  hideHeader?: boolean
  hideDoctor?: boolean
  className?: string
  hideRegister?: boolean
  auth0app?: 'admin' | 'webshop'
}) {
  const { t } = useTranslation()
  return (
    <AuthorizationLayout
      title={
        <>
          <div>{t('Login')}</div>
          <div className="text-primary-100">Apotheken-Marktplatz</div>
        </>
      }
      className={`w-11/12 xl:w-7/12 lg:w-7/12 flex flex-col content-center mt-10 px-4 ${
        className ?? ''
      }`}
      footer={
        hideDoctor ? (
          <></>
        ) : (
          <div className="w-full mt-[150px]">
            <FooterText />
          </div>
        )
      }
      hideHeader={hideHeader}
      children={
        <UserSignInMethod
          onClose={onLogin}
          onRegister={onRegister}
          auth0app={auth0app}
          hideRegister={hideRegister}
        />
      }
    />
  )
}

export default UserSignIn
