import useAuth0, {
  openRoutes
} from '@gruene-brise/data-access/lib/hooks/useAuth0'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import WebshopHeader, { WebshopHeaderProp } from '../navigation/WebshopHeader'
import classNames from 'classnames'
import { useAuthState } from '@gruene-brise/data-access/lib/state/useAuthState'

export interface WebshopNavigationProp extends WebshopHeaderProp {
  children: React.ReactNode
  wrapper?: string
  isDark?: boolean
  greyBg?: boolean
}

const WebshopNavigation = ({
  children,
  showTopMenu,
  wrapper,
  isDark,
  greyBg = true
}: WebshopNavigationProp) => {
  const router = useRouter()
  const { isLoggedIn } = useAuth0('webshop')

  useEffect(() => {
    if (!openRoutes.filter((i) => i !== '/').includes(router.pathname)) return
    if (
      isLoggedIn &&
      openRoutes.filter((i) => i !== '/').includes(router.pathname)
    ) {
      router.push('/')
    }
  }, [router])

  return (
    <div
      className={classNames(
        ` ${wrapper} h-screen w-screen min-h-screen scrollbar-hide overflow-hidden`,
        {
          'bg-primary-10': greyBg
        }
      )}
    >
      <WebshopHeader isDark={isDark} showTopMenu={showTopMenu} />
      <main>{children}</main>
    </div>
  )
}

export default WebshopNavigation
